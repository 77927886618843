import React, { useEffect, useState } from "react";
import { useAuth } from "@/components/AuthContext";
import { useParams, useNavigate } from "react-router-dom";
import { fetchProduct, updateProduct, deleteProduct } from "@/services/dashboardService";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardHeader,
  CardContent,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";

type Product = {
  id: number;
  product_type: string;
  profile: {
    name: string;
    description: string;
  };
  stocks: {
    status: string;
  }[];
};

const DashboardProductsDetailPage: React.FC = () => {
  const { currentUser } = useAuth();
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  const siteId = currentUser?.site_id;

  // プロダクト詳細を取得
  useEffect(() => {
    const loadProduct = async () => {
      if (!siteId || !productId) return;

      try {
        const data = await fetchProduct(Number(siteId), Number(productId));
        setProduct(data);
      } catch (error) {
        toast.error("プロダクト情報の取得に失敗しました");
      } finally {
        setLoading(false);
      }
    };

    loadProduct();
  }, [siteId, productId]);

  // プロダクトの更新処理
  const handleSave = async () => {
    if (!siteId || !productId || !product) return;

    setIsSaving(true);
    try {
      await updateProduct(Number(siteId), Number(productId), {
        product_type: product.product_type,
        profile_attributes: { // 修正: profile -> profile_attributes
          name: product.profile.name,
          description: product.profile.description,
        },
      });
      toast.success("プロダクトが更新されました");
    } catch (error) {
      toast.error("プロダクトの更新に失敗しました");
    } finally {
      setIsSaving(false);
    }
  };

  // プロダクトの削除処理
  const handleDelete = async () => {
    if (!siteId || !productId) return;

    try {
      await deleteProduct(Number(siteId), Number(productId));
      toast.success("プロダクトが削除されました");
      navigate("/dashboard/products"); // 一覧ページへリダイレクト
    } catch (error) {
      toast.error("プロダクトの削除に失敗しました");
    }
  };

  if (loading) return <p>読み込み中...</p>;
  if (!product) return <p>プロダクトが見つかりません。</p>;

  return (
    <div className="container mx-auto">
      <Card>
        <CardHeader>
          <CardTitle>{product.profile.name}</CardTitle>
          <CardDescription>プロダクト詳細を編集・管理します</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid gap-4">
            <div>
              <Label htmlFor="product_name">名前</Label>
              <Input
                id="product_name"
                value={product.profile.name}
                onChange={(e) =>
                  setProduct((prev) =>
                    prev ? { ...prev, profile: { ...prev.profile, name: e.target.value } } : prev
                  )
                }
              />
            </div>
            <div>
              <Label htmlFor="product_description">説明</Label>
              <Textarea
                id="product_description"
                value={product.profile.description}
                onChange={(e) =>
                  setProduct((prev) =>
                    prev
                      ? { ...prev, profile: { ...prev.profile, description: e.target.value } }
                      : prev
                  )
                }
              />
            </div>
            <div>
              <Label htmlFor="product_type">タイプ</Label>
              <Input
                id="product_type"
                value={product.product_type}
                onChange={(e) =>
                  setProduct((prev) =>
                    prev ? { ...prev, product_type: e.target.value } : prev
                  )
                }
              />
            </div>
          </div>
        </CardContent>
        <div className="flex justify-end gap-2 p-4">
          <Button variant="destructive" onClick={handleDelete}>
            削除
          </Button>
          <Button onClick={handleSave} disabled={isSaving}>
            {isSaving ? "保存中..." : "保存"}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default DashboardProductsDetailPage;
