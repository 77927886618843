import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "@/components/AuthContext";
import { fetchSites, Site, fetchEvents, Event } from "@/services/customerService";

const IndexPage: React.FC = () => {
  const { currentUser } = useAuth();

  const [sites, setSites] = useState<Site[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [sitesLoading, setSitesLoading] = useState<boolean>(true);
  const [eventsLoading, setEventsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadSites = async () => {
      try {
        const sitesData = await fetchSites();
        setSites(sitesData);
      } catch (err: any) {
        setError("サイトの取得に失敗しました。");
      } finally {
        setSitesLoading(false);
      }
    };

    const loadEvents = async () => {
      try {
        const eventsData = await fetchEvents();
        setEvents(eventsData.slice(0, 3)); // 人気イベントを3件だけ表示
      } catch (err: any) {
        setError("イベントの取得に失敗しました。");
      } finally {
        setEventsLoading(false);
      }
    };

    loadSites();
    loadEvents();
  }, []);

  return (
    <>
      {!currentUser && (
        <>
          <section className="relative bg-[url('/hero.jpg')] bg-cover bg-center bg-no-repeat">
            <div className="absolute inset-0 bg-gray-900/60 from-gray-900/95 to-gray-900/25"></div>
            <div className="relative mx-auto max-w-screen-xl px-4 py-8 md:py-16 lg:py-36" >
              <div className="mx-auto text-center">
                <h1 className="text-white font-extrabold text-4xl sm:text-6xl md:text-7xl pt-4">
                  オリパサイトを<br />5分で開設
                </h1>

              <p className="mx-auto mt-4 max-w-lg text-white sm:text-xl/relaxed">
              JOYBASE はオリパサイトを簡単に開設できるサービスです。
              古物商許可証と Stripe アカウントとがあればすぐに利用が開始できます。
              </p>
              </div>
              <div className="mt-12 text-center">
                <Link
                  to="/signup?type=site"
                  className="inline-block bg-orange-600 px-12 md:px-24 py-4 md:text-xl font-bold text-white transition hover:bg-orange-700 focus:outline-none focus:ring focus:ring-orange-400"
                >
                   無料でオリパサイトを開設する
                </Link>
              </div>
            </div>
          </section>
          <section className="bg-gray-900 text-white">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="mx-auto max-w-lg text-center">
                <h2 className="text-xl font-bold sm:text-3xl">オリパサイト開設に必要なもの</h2>

                <p className="mt-4 text-gray-300">
                以下の必要書類を揃えるだけで簡単にオリパサイトを始められます。
                </p>
              </div>

              <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                <div
                    className="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-teal-500/10 hover:shadow-teal-500/10"
                    >
                    <svg className="size-10 text-teal-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 9h3m-3 3h3m-3 3h3m-6 1c-.306-.613-.933-1-1.618-1H7.618c-.685 0-1.312.387-1.618 1M4 5h16a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Zm7 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"/>
                    </svg>
                  <h2 className="mt-4 text-xl font-bold text-white">本人確認書類</h2>

                  <p className="mt-1 text-sm text-gray-300">
                  運転免許証、パスポートなどの身分証明書が必要です。
                  </p>
                </div>

                <div
                    className="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-teal-500/10 hover:shadow-teal-500/10"
                    >
                    <svg className="size-10 text-teal-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m8.032 12 1.984 1.984 4.96-4.96m4.55 5.272.893-.893a1.984 1.984 0 0 0 0-2.806l-.893-.893a1.984 1.984 0 0 1-.581-1.403V7.04a1.984 1.984 0 0 0-1.984-1.984h-1.262a1.983 1.983 0 0 1-1.403-.581l-.893-.893a1.984 1.984 0 0 0-2.806 0l-.893.893a1.984 1.984 0 0 1-1.403.581H7.04A1.984 1.984 0 0 0 5.055 7.04v1.262c0 .527-.209 1.031-.581 1.403l-.893.893a1.984 1.984 0 0 0 0 2.806l.893.893c.372.372.581.876.581 1.403v1.262a1.984 1.984 0 0 0 1.984 1.984h1.262c.527 0 1.031.209 1.403.581l.893.893a1.984 1.984 0 0 0 2.806 0l.893-.893a1.985 1.985 0 0 1 1.403-.581h1.262a1.984 1.984 0 0 0 1.984-1.984V15.7c0-.527.209-1.031.581-1.403Z"/>
                    </svg>

                  <h2 className="mt-4 text-xl font-bold text-white">Stripe アカウント</h2>

                  <p className="mt-1 text-sm text-gray-300">
                  ショップ利用者の決済を受けるために Stripe アカウントが必要となります。
                  Stripe を介することで安全にショップ売上を受け取ることができます。
                  </p>
                </div>

                <div
                    className="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-teal-500/10 hover:shadow-teal-500/10"
                    >
                    <svg className="size-10 text-teal-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 3v4a1 1 0 0 1-1 1H5m4 8h6m-6-4h6m4-8v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Z"/>
                    </svg>

                  <h2 className="mt-4 text-xl font-bold text-white">古物商許可証</h2>

                  <p className="mt-1 text-sm text-gray-300">
                  トレーディングカードの販売を行う場合は古物商許可証が必要となります。
                  </p>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      <section className="bg-gray-100">
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
          <div className="mx-auto max-w-lg text-center">
            <h2 className="text-lg font-bold sm:text-2xl">人気のイベント一覧</h2>
          </div>
          {eventsLoading && <p className="text-center">読み込み中...</p>}
          {error && <p className="text-center text-red-500">{error}</p>}
          {!eventsLoading && !error && (
            <div className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {events.map((event) => (
                <Link
                  to={`/oripa/${event.site.id}/${event.uid}`}
                  key={event.uid}
                  className="relative rounded-lg border border-gray-300 bg-white p-6 shadow-md"
                >
                  <div className="flex justify-center items-center h-40 bg-gray-200 rounded-lg">
                    <span className="text-gray-500 text-xl font-bold">{event.name}</span>
                  </div>
                  <h3 className="mt-4 text-lg font-bold text-center text-gray-800">{event.name}</h3>
                  <p className="mt-2 text-sm text-center text-gray-600">{event.description}</p>
                </Link>
              ))}
            </div>
          )}
        </div>
      </section>

      {/* 人気オリパサイト一覧 */}
      <section className="bg-gray-100">
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
          <div className="mx-auto max-w-lg text-center">
            <h2 className="text-lg font-bold sm:text-2xl">人気オリパサイト一覧</h2>
          </div>
          {sitesLoading && <p className="text-center">読み込み中...</p>}
          {error && <p className="text-center text-red-500">{error}</p>}
          {!sitesLoading && !error && (
            <ul className="grid grid-cols-1 gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-3">
              {sites.slice(0, 3).map((site) => (
                <Link
                  to={`/oripa/${site.id}`}
                  className="block"
                  key={site.id}
                >
                  <img
                    alt=""
                    src="https://images.unsplash.com/photo-1605721911519-3dfeb3be25e7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                    className="h-64 w-full object-cover sm:h-80 lg:h-96"
                  />

                  <h3 className="mt-4 text-lg font-bold text-gray-900 sm:text-xl">{site.name}</h3>
                  <p className="mt-2 max-w-sm text-gray-700">{site.description}</p>
                </Link>
              ))}
            </ul>
          )}
        </div>
      </section>
    </>
  );
};
export default IndexPage;
