import apiClient from "@/lib/apiClient";

type Event = {
  id: number;
  name: string;
  description: string;
  starts_at: string;
  ends_at: string;
  category_id: number | null;
  category?: {
    id: number;
    name: string;
    slug: string;
  };
  status: "draft" | "published" | "archived";
  file_urls: string[];
};

export const fetchEvents = async (siteId: number): Promise<Event[]> => {
  const response = await apiClient.get<Event[]>(`/dashboard/sites/${siteId}/events`);
  return response.data;
};

export const fetchEvent = async (siteId: number, eventId: number): Promise<Event> => {
  const response = await apiClient.get<Event>(`/dashboard/sites/${siteId}/events/${eventId}`);
  return response.data;
};

export const updateEvent = async (
  siteId: number,
  eventId: number,
  updatedEvent: Partial<Event>
): Promise<Event> => {
  const response = await apiClient.put<Event>(
    `/dashboard/sites/${siteId}/events/${eventId}`,
    updatedEvent
  );
  return response.data;
};

export const createEvent = async (
  siteId: number,
  newEvent: Partial<Event>
): Promise<Event> => {
  const response = await apiClient.post<Event>(
    `/dashboard/sites/${siteId}/events`,
    newEvent
  );
  return response.data;
};

type Category = {
  id: number;
  name: string;
  slug: string;
};

export const fetchCategories = async (siteId: number): Promise<Category[]> => {
  const response = await apiClient.get<Category[]>(`/dashboard/sites/${siteId}/categories`);
  return response.data;
};

type ProductProfile = {
  name: string;
  description: string;
};

type ProductStock = {
  status: string;
};

type Product = {
  id: number;
  product_type: string;
  profile: ProductProfile;
  stocks: ProductStock[];
};

export const fetchProducts = async (siteId: number): Promise<Product[]> => {
  const response = await apiClient.get<Product[]>(`/dashboard/sites/${siteId}/products`);
  return response.data;
};

// プロダクトの詳細を取得
export const fetchProduct = async (siteId: number, productId: number): Promise<Product> => {
  const response = await apiClient.get<Product>(`/dashboard/sites/${siteId}/products/${productId}`);
  return response.data;
};

// プロダクトを作成
export const createProduct = async (siteId: number, newProduct: Partial<Product>): Promise<Product> => {
  const response = await apiClient.post<Product>(`/dashboard/sites/${siteId}/products`, newProduct);
  return response.data;
};

// プロダクトを更新
export const updateProduct = async (
  siteId: number,
  productId: number,
  updatedProduct: Partial<Product>
): Promise<Product> => {
  const response = await apiClient.put<Product>(
    `/dashboard/sites/${siteId}/products/${productId}`,
    updatedProduct
  );
  return response.data;
};

// プロダクトを削除
export const deleteProduct = async (siteId: number, productId: number): Promise<void> => {
  await apiClient.delete(`/dashboard/sites/${siteId}/products/${productId}`);
};

// アップロードされたファイルの情報
type AttachmentResponse = {
  message: string;
  file_urls: string[];
};

// ファイルをアップロード
export const uploadAttachments = async (
  attachableType: string,
  attachableId: number,
  file: File,
  identifier?: string
): Promise<AttachmentResponse> => {
  const formData = new FormData();
  formData.append("attachable_type", attachableType);
  formData.append("attachable_id", String(attachableId));
  formData.append("file", file);
  formData.append("identifier", identifier);

  const response = await apiClient.post<AttachmentResponse>(
    "/dashboard/attachments",
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );

  return response.data;
};

// 特定のファイルを削除
export const deleteAttachment = async (
  fileId: string
): Promise<{ message: string }> => {
  const response = await apiClient.delete<{ message: string }>(`/dashboard/attachments/${fileId}`);

  return response.data;
};

export type Site = {
  id: number;
  name: string;
  description: string;
  subdomain: string;
  events_count: number;
  products_count: number;
  orders_count: number;
};

export const fetchSite = async (siteId: number): Promise<Site> => {
  const response = await apiClient.get<Site>(`/dashboard/sites/${siteId}`);
  return response.data;
};

export const updateSite = async (
  siteId: number,
  siteData: Partial<Site>
): Promise<Site> => {
  const response = await apiClient.put<Site>(`/dashboard/sites/${siteId}`, siteData);
  return response.data;
};
