// application.ts
import ReactDOM from "react-dom/client";
import App from "@/components/App";

import '../index.css'

// マウントポイントを探してレンダリング
document.addEventListener("DOMContentLoaded", () => {
  const element = document.getElementById("react-root");
  if (element) {
    const props = JSON.parse(element.dataset.props || "{}");
    ReactDOM.createRoot(element).render(<App {...props} />);
  }
});
