import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { AuthProvider } from "@/components/AuthContext";
import { PublicRoute, PrivateRoute } from "@/components/route-guards";
import { Toaster } from "@/components/ui/sonner"

/* Customer Pages */
import Header from "@/components/header";
import Footer from "@/components/footer";
import SignupPage from "@/pages/signup";
import LoginPage from "@/pages/login";
import ResetPasswordPage from "@/pages/reset-password";
import IndexPage from "@/pages/index";
import EventsDetailsPage from "@/pages/events/details";
import SiteDetailPage from "@/pages/site-detail";
import PointPage from "@/pages/point";
import HomeAddressPage from "@/pages/home-address";
import OrdersPage from "@/pages/orders";
import TradeLawPage from "@/pages/trade-law";
import TermsPage from "@/pages/terms";
import PrivacyPage from "@/pages/privacy";

/* Dashboard Pages */
import DashboardPage from "@/pages/dashboard";
import DashboardIndexPage from "@/pages/dashboard/index";
import DashboardEventsPage from "@/pages/dashboard/events";
import DashboardEventsDetailsPage from "@/pages/dashboard/events/details";
import DashboardProductsPage from "@/pages/dashboard/products";
import DashboardProductsDetailPage from "@/pages/dashboard/products/details";
import DashboardOrdersPage from "@/pages/dashboard/orders";
import DashboardProfilePage from "@/pages/dashboard/profile";
import DashboardSettingsPage from "@/pages/dashboard/settings";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <AppRoutes />
      </Router>
    </AuthProvider>
  );
};

const AppRoutes: React.FC = () => {
  const location = useLocation();

  // フッターを非表示にするパス
  const hideFooterPaths = [/^\/oripa\/\d+\/[a-zA-Z0-9-]+$/];

  // 現在のパスが非表示対象か判定
  const shouldHideFooter = hideFooterPaths.some((regex) => regex.test(location.pathname));

  return (
    <>
      <Header />
      <main className="pt-16">
        <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route path="/signup" element={<PublicRoute><SignupPage /></PublicRoute>} />
          <Route path="/login" element={<PublicRoute><LoginPage /></PublicRoute>} />
          <Route path="/resets_password" element={<PublicRoute><ResetPasswordPage /></PublicRoute>} />
          <Route path="/oripa/:id" element={<SiteDetailPage />} />
          <Route path="/oripa/:siteId/:eventId" element={<EventsDetailsPage />} />
          <Route path="/point" element={<PrivateRoute><PointPage /></PrivateRoute>} />
          <Route path="/home-address" element={<PrivateRoute><HomeAddressPage /></PrivateRoute>} />
          <Route path="/orders" element={<PrivateRoute><OrdersPage /></PrivateRoute>} />
          <Route path="/trade-law" element={<TradeLawPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/dashboard" element={<PrivateRoute><DashboardPage /></PrivateRoute>}>
            <Route path="" element={<PrivateRoute><DashboardIndexPage /></PrivateRoute>} />
            <Route path="events" element={<PrivateRoute><DashboardEventsPage /></PrivateRoute>} />
            <Route path="events/:id" element={<PrivateRoute><DashboardEventsDetailsPage /></PrivateRoute>} />
            <Route path="products" element={<PrivateRoute><DashboardProductsPage /></PrivateRoute>} />
            <Route path="products/:productId" element={<PrivateRoute><DashboardProductsDetailPage /></PrivateRoute>} />
            <Route path="orders" element={<PrivateRoute><DashboardOrdersPage /></PrivateRoute>} />
            <Route path="profile" element={<PrivateRoute><DashboardProfilePage /></PrivateRoute>} />
            <Route path="settings" element={<PrivateRoute><DashboardSettingsPage /></PrivateRoute>} />
          </Route>
        </Routes>
      </main>
      {!shouldHideFooter && <Footer />}
      <Toaster position="top-center" />
    </>
  );
};

export default App;
