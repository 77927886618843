import React, { useEffect, useState } from "react";
import { useAuth } from "@/components/AuthContext";
import { useParams } from "react-router-dom";
import { toast } from "sonner"
import { fetchEvent, updateEvent, fetchCategories, uploadAttachments, deleteAttachment } from "@/services/dashboardService";
import {
  ChevronLeft,
  Home,
  LineChart,
  Package,
  Package2,
  PanelLeft,
  PlusCircle,
  Search,
  Settings,
  ShoppingCart,
  Upload,
  Users2,
} from "lucide-react"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Textarea } from "@/components/ui/textarea"
import {
  ToggleGroup,
  ToggleGroupItem,
} from "@/components/ui/toggle-group"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"

type Event = {
  id: number;
  name: string;
  description: string;
  starts_at: string;
  ends_at: string;
  category_id: number | null; // カテゴリー ID を追加
  status: "draft" | "published" | "archived";
  attachments: Attachment[];
};

type Category = {
  id: number;
  name: string;
  slug: string;
};

type Attachment = {
  id: number;
  attachable_type: string;
  attachable_id: number;
  identifier: string;
  created_at: string;
  updated_at: string;
  url: string;
};

const DashboardEventsDetailsPage: React.FC = () => {
  const { currentUser } = useAuth();
  const [event, setEvent] = useState<Event | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false); // 保存中の状態管理

  const siteId = currentUser?.site_id;
  const { id } = useParams<{ id: string }>(); // 修正: URLからパラメータを取得

  useEffect(() => {
    if (!id || !siteId) return;

    const loadData = async () => {
      setLoading(true);
      try {
        const [eventData, categoryData] = await Promise.all([
          fetchEvent(Number(siteId), Number(id)),
          fetchCategories(Number(siteId)),
        ]);
        setEvent(eventData);
        setCategories(categoryData);
      } catch (err: any) {
        setError(err.message || "データ取得に失敗しました");
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [id, siteId]);

  const handleSave = async () => {
    if (!id || !siteId || !event) return;

    setIsSaving(true);
    try {
      const updatedEvent = await updateEvent(Number(siteId), Number(id), {
        name: event.name,
        description: event.description,
        category_id: event.category_id,
      });
      toast.success("イベントが更新されました");
    } catch (err: any) {
      setError(err.message || "保存に失敗しました");
    } finally {
      setIsSaving(false);
    }
  };

  const handleStatusChange = async (value: string) => {
    if (!event) return;
    try {
      await updateEvent(Number(siteId), Number(id), {
        status: value as Event["status"],
      });
      setEvent((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          status: value as Event["status"],
        };
      });
      toast.success("ステータスが更新されました");
    } catch (err: any) {
      console.error(err);
      setError("ステータスを更新できませんでした");
    } finally {
      setLoading(false);
    }
  }

  const handleFileUpload = async (identifier: string, event: React.ChangeEvent<HTMLInputElement>) => {
    if (!id || !event.target.files) return;

    const files = Array.from(event.target.files);
    try {
      const response = await uploadAttachments("Event", Number(id), files, identifier);

      setEvent((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          attachments: [...prev.attachments, ...response],
        };
      });
    } catch (err: any) {
      console.error(err);
      setError("画像をアップロードできませんでした");
    }
  };

  const handleFileDelete = async (attachmentId: number) => {
    try {
      await deleteAttachment(attachmentId);

      setEvent((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          attachments: prev.attachments.filter((attachment) => attachment.id !== attachmentId),
        };
      });
    } catch (err: any) {
      console.error(err);
      setError("画像を削除できませんでした");
    }
  };

  const getAttachmentUrl = (identifier: string) =>
    event?.attachments.find((attachment) => attachment.identifier === identifier)?.url;

  if (loading) return <p>読み込み中...</p>;
  if (error) return <p>{error}</p>;
  if (!event) return <p>データが見つかりません。</p>;

  return (
    <div className="container mx-auto">
      <div className="mx-auto grid flex-1 auto-rows-max gap-4">
        <div className="flex items-center gap-4">
          <Button asChild variant="outline" size="icon" className="h-7 w-7">
            <a href="/dashboard/events">
              <ChevronLeft className="h-4 w-4" />
              <span className="sr-only">Back</span>
            </a>
          </Button>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
            {event.name}
          </h1>
          <Badge variant="outline" className="ml-auto sm:ml-0">
            { event.status === "draft" ? "下書き" : event.status === "published" ? "公開中" : "アーカイブ済" }
          </Badge>
          <div className="hidden items-center gap-2 md:ml-auto md:flex">
            {/*
            <Button variant="outline" size="sm">
              変更を取消
            </Button>
            <Button size="sm" type="submit">保存</Button>
            */}
          </div>
        </div>
        <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
          <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
            <Card x-chunk="dashboard-07-chunk-0">
              <CardHeader>
                <CardTitle>概要</CardTitle>
                <CardDescription>イベント掲載情報を編集します</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-3">
                  <div className="grid gap-1">
                    <label>イベント名</label>
                    <Input
                      value={event.name}
                      onChange={(e) =>
                        setEvent((prev) => (prev ? { ...prev, name: e.target.value } : prev))
                      }
                      placeholder="AAイベント"
                    />
                  </div>
                  <div className="grid gap-1">
                    <label>概要</label>
                    <Textarea
                      value={event.description}
                      onChange={(e) =>
                        setEvent((prev) =>
                          prev ? { ...prev, description: e.target.value } : prev
                        )
                      }
                      placeholder="カードオリパです"
                    />
                  </div>
                  <div className="grid gap-1">
                    <Label htmlFor="category">カテゴリー</Label>
                    <Select
                      value={event.category_id}
                      onValueChange={(value) =>
                        setEvent((prev) =>
                          prev ? { ...prev, category_id: Number(value) } : prev
                        )
                      }
                    >
                      <SelectTrigger id="category" aria-label="掲載するカテゴリーを選択してください。">
                        <SelectValue placeholder="カテゴリー選択" />
                      </SelectTrigger>
                      <SelectContent>
                        {categories.map((category) => (
                          <SelectItem key={category.id} value={category.id}>
                            {category.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="flex justify-end">
                    <Button onClick={handleSave} disabled={isSaving}>
                      {isSaving ? "保存中..." : "保存"}
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card x-chunk="dashboard-07-chunk-1">
              <CardHeader>
                <CardTitle>Card</CardTitle>
                <CardDescription>
                  当選カードを登録します。
                </CardDescription>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-[100px]">ID</TableHead>
                      <TableHead>当選枚数</TableHead>
                      <TableHead>買い取り価格</TableHead>
                      <TableHead className="w-[100px]">当選ランク</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow>
                      <TableCell className="font-semibold">
                        GGPC-001
                      </TableCell>
                      <TableCell>
                        <Label htmlFor="stock-1" className="sr-only">
                          Stock
                        </Label>
                        <Input
                          id="stock-1"
                          type="number"
                          defaultValue="100"
                        />
                      </TableCell>
                      <TableCell>
                        <Label htmlFor="price-1" className="sr-only">
                          Price
                        </Label>
                        <Input
                          id="price-1"
                          type="number"
                          defaultValue="99.99"
                        />
                      </TableCell>
                      <TableCell>
                        <ToggleGroup
                          type="single"
                          defaultValue="s"
                          variant="outline"
                        >
                          <ToggleGroupItem value="s">S</ToggleGroupItem>
                          <ToggleGroupItem value="m">M</ToggleGroupItem>
                          <ToggleGroupItem value="l">L</ToggleGroupItem>
                        </ToggleGroup>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-semibold">
                        GGPC-002
                      </TableCell>
                      <TableCell>
                        <Label htmlFor="stock-2" className="sr-only">
                          Stock
                        </Label>
                        <Input
                          id="stock-2"
                          type="number"
                          defaultValue="143"
                        />
                      </TableCell>
                      <TableCell>
                        <Label htmlFor="price-2" className="sr-only">
                          Price
                        </Label>
                        <Input
                          id="price-2"
                          type="number"
                          defaultValue="99.99"
                        />
                      </TableCell>
                      <TableCell>
                        <ToggleGroup
                          type="single"
                          defaultValue="m"
                          variant="outline"
                        >
                          <ToggleGroupItem value="s">S</ToggleGroupItem>
                          <ToggleGroupItem value="m">M</ToggleGroupItem>
                          <ToggleGroupItem value="l">L</ToggleGroupItem>
                        </ToggleGroup>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-semibold">
                        GGPC-003
                      </TableCell>
                      <TableCell>
                        <Label htmlFor="stock-3" className="sr-only">
                          Stock
                        </Label>
                        <Input
                          id="stock-3"
                          type="number"
                          defaultValue="32"
                        />
                      </TableCell>
                      <TableCell>
                        <Label htmlFor="price-3" className="sr-only">
                          Stock
                        </Label>
                        <Input
                          id="price-3"
                          type="number"
                          defaultValue="99.99"
                        />
                      </TableCell>
                      <TableCell>
                        <ToggleGroup
                          type="single"
                          defaultValue="s"
                          variant="outline"
                        >
                          <ToggleGroupItem value="s">S</ToggleGroupItem>
                          <ToggleGroupItem value="m">M</ToggleGroupItem>
                          <ToggleGroupItem value="l">L</ToggleGroupItem>
                        </ToggleGroup>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
              <CardFooter className="justify-center border-t p-4">
                <Button size="sm" variant="ghost" className="gap-1">
                  <PlusCircle className="h-3.5 w-3.5" />
                  Add Variant
                </Button>
              </CardFooter>
            </Card>
          </div>
          <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
            <Card x-chunk="dashboard-07-chunk-3">
              <CardHeader>
                <CardTitle>公開ステータス</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6">
                  <div className="grid gap-3">
                    <Label htmlFor="status">ステータス</Label>
                    <Select value={event.status} onValueChange={handleStatusChange}>
                      <SelectTrigger id="status" aria-label="Select status">
                        <SelectValue placeholder="ステータスを選択" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="draft">下書き</SelectItem>
                        <SelectItem value="published">公開中</SelectItem>
                        <SelectItem value="archived">アーカイブ済</SelectItem>
                      </SelectContent>
                    </Select>
                    {isSaving && <p className="text-sm text-muted-foreground">保存中...</p>}
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card className="overflow-hidden" x-chunk="dashboard-07-chunk-4">
              <CardHeader>
                <CardTitle>サムネイル画像</CardTitle>
                <CardDescription>イベント画面に表示中の画像を編集します。</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-2">
                  <div className="aspect-square w-full rounded-md overflow-hidden">
                    {getAttachmentUrl("thumbnail") ? (
                      <div className="relative">
                        <img
                          alt="Main event image"
                          src={getAttachmentUrl("thumbnail")}
                          className="object-cover w-full h-full"
                        />
                        <Button
                          variant="destructive"
                          size="sm"
                          onClick={() =>
                            handleFileDelete(
                              event.attachments.find((a) => a.identifier === "thumbnail")!.id
                            )
                          }
                          className="absolute top-2 right-2"
                        >
                          削除
                        </Button>
                      </div>
                    ) : (
                      <img
                        alt="Placeholder image"
                        src="/placeholder.svg"
                        className="object-cover w-full h-full"
                      />
                    )}
                  </div>
                  <div className="grid grid-cols-3 gap-2">
                    <label className="flex aspect-square w-full items-center justify-center rounded-md border border-dashed cursor-pointer">
                      <Upload className="h-4 w-4 text-muted-foreground" />
                      <span className="sr-only">Upload</span>
                      <input
                        type="file"
                        multiple
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => handleFileUpload("thumbnail", e)}
                      />
                    </label>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card className="overflow-hidden" x-chunk="dashboard-07-chunk-4">
              <CardHeader>
                <CardTitle>イベント詳細説明画像</CardTitle>
                <CardDescription>イベントの詳細説明を記述した画像を編集します。</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-2">
                  <div className="aspect-square w-full rounded-md overflow-hidden">
                    {getAttachmentUrl("event_detail") ? (
                      <div className="relative">
                        <img
                          alt="Main event image"
                          src={getAttachmentUrl("event_detail")}
                          className="object-cover w-full h-full"
                        />
                        <Button
                          variant="destructive"
                          size="sm"
                          onClick={() =>
                            handleFileDelete(
                              event.attachments.find((a) => a.identifier === "event_detail")!.id
                            )
                          }
                          className="absolute top-2 right-2"
                        >
                          削除
                        </Button>
                      </div>
                    ) : (
                      <img
                        alt="Placeholder image"
                        src="/placeholder.svg"
                        className="object-cover w-full h-full"
                      />
                    )}
                  </div>
                  <div className="grid grid-cols-3 gap-2">
                    <label className="flex aspect-square w-full items-center justify-center rounded-md border border-dashed cursor-pointer">
                      <Upload className="h-4 w-4 text-muted-foreground" />
                      <span className="sr-only">Upload</span>
                      <input
                        type="file"
                        multiple
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => handleFileUpload("event_detail", e)}
                      />
                    </label>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2 md:hidden">
          <Button variant="outline" size="sm">
            Discard
          </Button>
          <Button size="sm">Save Product</Button>
        </div>
      </div>
    </div>
  );
};
export default DashboardEventsDetailsPage;
