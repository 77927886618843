import React, { useEffect, useState } from "react";
import { useAuth } from "@/components/AuthContext";
import { useNavigate } from "react-router-dom";
import { fetchEvents, createEvent } from "@/services/dashboardService";
import { List, LayoutGrid, ImagePlus } from "lucide-react";
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableCaption,
} from "@/components/ui/table"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"

import moment from "moment";

type Event = {
  id: number;
  name: string;
  description: string;
  starts_at: string;
  ends_at: string;
};

const DashboardEventsPage: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const siteId = currentUser?.site_id;

  useEffect(() => {
    const loadEvents = async () => {
      try {
        const data = await fetchEvents(siteId);
        setEvents(data);
      } catch (error: any) {
        setError(error.message || "データ取得に失敗しました");
      } finally {
        setLoading(false);
      }
    };

    loadEvents();
  }, [siteId]);

  const handleCreateNewEvent = async () => {
    try {
      const newEvent = {
        name: "新しいイベント",
        description: "新規作成されたイベントです。",
        starts_at: new Date().toISOString(),
        ends_at: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(), // 1週間後
        status: "draft",
      };

      const createdEvent = await createEvent(siteId, newEvent);
      navigate(`/dashboard/events/${createdEvent.id}`); // 詳細ページへ遷移
    } catch (error) {
      console.error("イベントの作成に失敗しました", error);
    }
  };
  return (
    <div className="container mx-auto">
      <Tabs defaultValue="grid-layout">
        <div className="mb-4 flex items-center justify-between">
          <h1 className="font-semibold tracking-tight text-xl">イベント一覧</h1>
          <div className="flex items-center gap-4">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="grid-layout">
                <LayoutGrid size={16} />
              </TabsTrigger>
              <TabsTrigger value="table-layout">
                <List size={16} />
              </TabsTrigger>
            </TabsList>
            <Button color="primary" onClick={handleCreateNewEvent}>
              <ImagePlus size={16} className="mr-1" />
              新規作成
            </Button>
          </div>
        </div>
        <TabsContent value="grid-layout">
          <div className="grid grid-cols-2 gap-4">
            {events.length ? (
              events.map((event) => (
                <a
                  key={event.id}
                  href={`/dashboard/events/${event.id}`}
                  className="group relative block overflow-hidden border border-gray-100 rounded-md"
                >
                  <img
                    src={event.attachments[0]?.url || "/placeholder.svg"}
                    alt={event.name}
                    className="h-64 w-full object-cover transition duration-500 group-hover:scale-105"
                  />
                  <div className="relative border border-gray-100 bg-white p-6">
                    {event.status === "draft" && (
                      <span className="whitespace-nowrap bg-gray-400 px-3 py-1.5 text-xs font-medium text-white">下書き</span>
                    )}
                    {event.status === "published" && (
                      <span className="whitespace-nowrap bg-yellow-400 px-3 py-1.5 text-xs font-medium">公開中</span>
                    )}
                    {event.status === "archived" && (
                      <span className="whitespace-nowrap bg-gray-400 px-3 py-1.5 text-xs font-medium text-white">アーカイブ</span>
                    )}
                    <h3 className="mt-4 text-lg font-medium text-gray-900">{event.name}</h3>
                    <p className="mt-1.5 text-sm text-gray-700">
                      {moment(event.starts_at).format("YYYY-MM-DD")} ~ {moment(event.ends_at).format("YYYY-MM-DD")}
                    </p>
                  </div>
                </a>
              ))
            ) : (
              <div className="text-center">{loading ? "Loading..." : error || "No data"}</div>
            )}
          </div>
        </TabsContent>
        <TabsContent value="table-layout">
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>イベント名</TableHead>
                  <TableHead>ステータス</TableHead>
                  <TableHead>公開期間</TableHead>
                  <TableHead></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {events.length ? (
                  events.map((event) => (
                    <TableRow key={event.id}>
                      <TableCell>{event.name}</TableCell>
                      <TableCell>
                        <Badge variant="outline" className="ml-auto sm:ml-0">
                          {event.status}
                        </Badge>
                      </TableCell>
                      <TableCell>
                        {moment(event.starts_at).format("YYYY-MM-DD")} ~ {moment(event.ends_at).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() => navigate(`/dashboard/events/${event.id}`)}
                        >
                          詳細
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} className="text-center">
                      {loading ? "Loading..." : error || "No data"}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};
export default DashboardEventsPage;
