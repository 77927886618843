import React, { useEffect, useState } from "react";
import { useAuth } from "@/components/AuthContext";
import { useNavigate } from "react-router-dom";
import { fetchProducts, createProduct } from "@/services/dashboardService";
import { List, LayoutGrid, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

type Product = {
  id: number;
  product_type: string;
  profile: {
    name: string;
    description: string;
  };
  stocks: {
    status: string;
  }[];
};

const DashboardProductsPage: React.FC = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const siteId = currentUser?.site_id;

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const data = await fetchProducts(siteId!);
        setProducts(data);
      } catch (error: any) {
        setError(error.message || "データ取得に失敗しました");
      } finally {
        setLoading(false);
      }
    };

    if (siteId) loadProducts();
  }, [siteId]);

  const handleCreateNewProduct = async () => {
    try {
      const newProduct = {
        product_type: "新しい商品タイプ",
        profile_attributes: { // 修正: profile -> profile_attributes
          name: "新しい商品",
          description: "新規作成された商品の説明です。",
        },
      };

      const createdProduct = await createProduct(siteId!, newProduct);
      navigate(`/dashboard/products/${createdProduct.id}`); // 詳細ページに遷移
    } catch (error) {
      console.error("商品の作成に失敗しました", error);
    }
  };

  return (
    <div className="container mx-auto">
      <Tabs defaultValue="grid-layout">
        <div className="mb-4 flex items-center justify-between">
          <h1 className="font-semibold tracking-tight text-xl">商品一覧</h1>
          <div className="flex items-center gap-4">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="grid-layout">
                <LayoutGrid size={16} />
              </TabsTrigger>
              <TabsTrigger value="table-layout">
                <List size={16} />
              </TabsTrigger>
            </TabsList>
            <Button color="primary" onClick={handleCreateNewProduct}>
              <Plus size={16} className="mr-1" />
              新規追加
            </Button>
          </div>
        </div>
        <TabsContent value="grid-layout">
          <div className="grid grid-cols-2 gap-4">
            {products.length ? (
              products.map((product) => (
                <a
                  key={product.id}
                  href={`/dashboard/products/${product.id}`}
                  className="group relative block overflow-hidden border border-gray-100 rounded-md"
                >
                  <div className="relative border border-gray-100 bg-white p-6">
                    <h3 className="mt-4 text-lg font-medium text-gray-900">{product.profile.name}</h3>
                    <p className="mt-1.5 text-sm text-gray-700">{product.profile.description}</p>
                    <p className="mt-1.5 text-sm text-gray-700">種別: {product.product_type}</p>
                    <p className="mt-1.5 text-sm text-gray-700">
                      在庫: {product.stocks[0]?.status || "在庫なし"}
                    </p>
                  </div>
                </a>
              ))
            ) : (
              <div className="text-center">{loading ? "Loading..." : error || "No data"}</div>
            )}
          </div>
        </TabsContent>
        <TabsContent value="table-layout">
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>名前</TableHead>
                  <TableHead>概要</TableHead>
                  <TableHead>種別</TableHead>
                  <TableHead>在庫状況</TableHead>
                  <TableHead>操作</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {products.length ? (
                  products.map((product) => (
                    <TableRow key={product.id}>
                      <TableCell>{product.profile.name}</TableCell>
                      <TableCell>{product.profile.description}</TableCell>
                      <TableCell>{product.product_type}</TableCell>
                      <TableCell>{product.stocks[0]?.status || "在庫なし"}</TableCell>
                      <TableCell>
                        <Button
                          size="sm"
                          onClick={() => navigate(`/dashboard/products/${product.id}`)}
                        >
                          詳細
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} className="text-center">
                      {loading ? "Loading..." : error || "No data"}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default DashboardProductsPage;
