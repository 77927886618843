import React, { useEffect, useState } from "react";
import { useAuth } from "@/components/AuthContext";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { fetchSite } from "@/services/dashboardService";

const DashboardPage: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [site, setSite] = useState<any>({});
  const { currentUser } = useAuth();

  useEffect(() => {
    const loadSite = async () => {
      const data = await fetchSite(currentUser?.site_id);
      setSite(data);
    };

    loadSite();
  }, []);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const menuItems = [
    { name: `投稿(${site.events_count || 0})`, path: "/dashboard/events" },
    { name: `商品(${site.products_count || 0})`, path: "/dashboard/products" },
    { name: `注文(${site.orders_count || 0})`, path: "/dashboard/orders" },
    { name: "店舗情報", path: "/dashboard/profile" },
    { name: "各種設定", path: "/dashboard/settings" },
  ];

  return (
    <div className="bg-gray-100 px-8 pt-4 pb-32">
      <div className="flex min-h-screen">
        {/* サイドメニュー */}
        <aside
          className={`w-52 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform border border-gray-200 transition-transform duration-200 ease-in-out z-10 bg-white ${
            menuOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-200 ease-in-out lg:relative lg:translate-x-0`}
        >
          <div className="text-center text-lg font-bold mb-4">
            <Link to="/dashboard" className="hover:text-yellow-400">
              {site.name}
            </Link>
          </div>
          <nav>
            {menuItems.map((item) => (
              <Link
                key={item.name}
                to={item.path}
                className="block py-2.5 px-4 rounded transition duration-200 text-sm hover:bg-gray-200"
              >
                {item.name}
              </Link>
            ))}
          </nav>
        </aside>

        {/* メインコンテンツ */}
        <div className="flex-1">
          {/* ヘッダー */}
          <header className="bg-gray-800 text-white flex justify-between items-center px-4 py-3 shadow-md lg:hidden">
            <button
              onClick={toggleMenu}
              className="text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-400"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
            <h1 className="text-xl font-bold">Dashboard</h1>
          </header>

          {/* コンテンツ */}
          <main className="p-8 bg-white ml-4 border border-gray-200">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
