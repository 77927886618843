import React, { useEffect, useState } from "react";
import { useAuth } from "@/components/AuthContext";
import { fetchSite } from "@/services/dashboardService";
import { ExternalLink } from "lucide-react";
import { Link } from "react-router-dom";

const DashboardIndexPage: React.FC = () => {
  const { currentUser } = useAuth();
  const [site, setSite] = useState<any>({});

  useEffect(() => {
    const loadSite = async () => {
      const data = await fetchSite(currentUser?.site_id);
      setSite(data);
    };

    loadSite();
  }, []);

  return (
    <div>
      <div className="flex gap-2 items-center mb-4">
        <h1 className="text-2xl font-bold">{site.name}</h1>
        <Link to="/dashboard/settings" className="flex items-center space-x-1 text-sm text-gray-500 hover:text-gray-700">
          <ExternalLink size={16} />
        </Link>
      </div>
      <p className="text-gray-600">{site.description}</p>
    </div>
  );
};

export default DashboardIndexPage;
